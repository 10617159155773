// .country-section {
//     .country-box-outer {
//         display: grid;
//         grid-template-columns: 1fr;
//         gap: 15px;

//         .country-box {
//             position: relative;

//             &::after {
//                 content: '';
//                 background: rgb(199, 0, 150);
//                 background: linear-gradient(180deg, rgba(199, 0, 150, 0) 17%, rgba(20, 20, 20, 0.835171568627451) 100%);
//                 position: absolute;
//                 left: 0;
//                 right: 0;
//                 bottom: 0;
//                 height: 300px;
//             }

//             .title {
//                 left: 15px;
//                 right: 15px;
//                 bottom: 20px;
//                 z-index: 9;

//             }

//         }
//     }

//     h1 {
//         text-transform: uppercase;
//     }
// }

// @media (min-width:35rem) {
//     .country-section {
//         .country-box-outer {
//             grid-template-columns: 1fr 1fr;
//         }
//     }
// }

// @media (min-width:62rem) {
//     .country-section {
//         .country-box-outer {
//             grid-template-columns: 1fr 1fr 1fr 1fr;

//             h1 {
//                 font-size: $base * 2 !important;
//                 line-height: $base * 2.5;
//             }

//             .country-box {
//                 height: 55vh;
//             }
//         }
//     }
// }

// @media (min-width:75rem) {}

// @media (min-width:93rem) {
//     .country-section {
//         .country-box-outer {
//             gap: 40px;

//             h1 {
//                 font-size: $base * 3 !important;
//                 line-height: $base * 4.5;
//             }

//             .country-box {
//                 height: 75vh;

//                 .title {
//                     left: 40px;
//                     right: 40px;
//                     bottom: 40px;

//                 }
//             }
//         }
//     }
// }
.slick-slide img{transition: all 0.3s;}
.country-section {
    .slick-track {
        display: flex;
        gap: 50px;


        .country-box {
            display: block;

            &::after {
                content: '';
                background: rgb(199, 0, 150);
                background: linear-gradient(180deg, rgba(199, 0, 150, 0) 17%, rgba(20, 20, 20, 0.835171568627451) 100%);
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 300px;
            }

            .title {
                z-index: 9;
                bottom: 20px;
                left: 20px;
                right: 20px;
            }

            @media (min-width:100rem) {
                .title {
                    left: 40px;
                    right: 40px;
                    bottom: 40px;
                }
            }



        }
    }

    @media (max-width:35rem) {
        // .slick-slide[data-index=1] {
        //     transform: translate(-15px, 0);
        // }
    }
}

.slick-dots {
    li {
        button {
            &::before {
                color: $light-color !important;
            }
        }
    }
}

.slick-prev {
    left: 0 !important;
    z-index: 99;
}

.slick-next {
    right: 0 !important;
    z-index: 99;
}