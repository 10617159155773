.topfooter {
    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    p.desc {
        width: 100%;
    }

    .contary-flag {
        display: grid;

        gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width:35rem) {
        .contary-flag {
            display: grid;

            gap: 20px;
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media (min-width:62rem) {
        .contary-flag {
            display: grid;

            gap: 20px;
            grid-template-columns: repeat(6, 1fr);
        }
    }

    @media (min-width:100rem) {
        p.desc {
            width: 60%;
        }

        .contary-flag {
            gap: 50px;
        }
    }
}

.bottomfooter {
    border-top: 5px solid #303030;
    display: grid;
    grid-template-columns: 1fr;

    h3 {
        margin-top: 30px;
    }

    @media (min-width:49rem) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width:100rem) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    p span {
        display: inline-block;
        width: 100px;
    }

    .foot-class {
        display: grid;
        grid-template-columns: 1fr 1fr;

        p {
            a {
                color: $light-color;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.bottomfooter2 {
    text-align: center;

    a {
        color: $primary;
        text-decoration: none;

        &:hover {
            color: $light-color;
        }
    }

    .d-grid {
        div {
            margin-bottom: 5px;
            margin-top: 5px;
        }

        @media (min-width:62rem) {
            grid-template-columns: 1fr 1fr;

            div:nth-child(1) {
                text-align: start;
            }

            div:nth-child(2) {
                text-align: end;
            }
        }
    }

}