.universities-contact {
    background: $light-color;

    .uni-con {

        @media (min-width:62rem) {
            display: grid;
            grid-template-columns: 1fr 1.4fr;

        }

        .universities-section {
            position: relative;
            height: 100%;

            .university-logo {
                position: absolute;
                bottom: 50px;
                background: #fff;
                padding: $base;
                left: 0;
                right: 0;

                @media (min-width:48rem) {
                    &::before {
                        content: '';
                        position: absolute;
                        right: 0;
                        background: #141414;
                        background: linear-gradient(90deg, rgba(199, 0, 150, 0) 60%, rgba(20, 20, 20, 0.700718) 100%);
                        height: 100%;
                        width: 100%;
                        top: 0;
                        z-index: 9;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        background: #141414;
                        background: linear-gradient(-90deg, rgba(199, 0, 150, 0) 60%, rgba(20, 20, 20, 0.700718) 100%);
                        height: 100%;
                        width: 100%;
                        top: 0;
                        z-index: 9;
                    }

                }
            }

            .bg-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .slick-slide {
        text-align: center;
    }

    .slick-track {
        display: flex;
        gap: 7px;
    }

    .slick-prev::before,
    .slick-next::before {
        color: $secondary;
    }


}

.universities-contact .slick-slide {
    text-align: center;

    img {
        display: inline-block !important;
    }
}