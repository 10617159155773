.class-structure {
    h2 {
        text-transform: uppercase;
    }

    img {
        width: 100%
    }


    @media (min-width:62rem) {
        .d-grid {
            grid-template-columns: 1fr 1fr;
            grid-gap: 50px;

            .d-flex {
                justify-content: center;
                align-items: center;
            }
        }

    }
}

.preparation-section {
    img {
        width: 100%;
        display: none;
    }

    @media (min-width:100rem) {
        .d-grid {
            grid-template-columns: 1.5fr 1fr;
            grid-gap: 50px;

            img {
                display: block;
            }

            .d-flex {
                align-items: center;
            }
        }
    }
}

.classes-test-prepartion {
    img {
        width: 100%;
        display: none;
    }

    .d-grid {
        grid-template-columns: 1fr;
    }

    @media (min-width:100rem) {
        img {
            display: block;
        }

        .d-grid {
            grid-template-columns: 1fr 1fr;
            grid-gap: 50px;

            .d-flex {
                align-items: center;
            }
        }
    }
}

._2p3a{width: 100%;}
.text-white{
    color:#fff;
}
.w-100{
    width: 100%;
}
.socialMediaOuter{
    display: grid;
    gap: 100px;
    grid-template-columns: 1fr;
    .mobile{
                display: block;
            }
            .desktop{
                display: none;
            }
    @media (min-width:600px) {
            grid-template-columns: 1fr;
            
    }
    @media (min-width:991px) {
            grid-template-columns: 1fr;
    }
    @media (min-width:1200px) {
        grid-template-columns: 1.5fr 3fr;
        .mobile{
                display: none;
            }
            .desktop{
                display: block;
            }
    }
}
.teamOuterBox{
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    @media (min-width:600px) {
            grid-template-columns: 1fr 1fr;
    }
    @media (min-width:991px) {
            grid-template-columns: 1fr 1fr;
    }
    @media (min-width:1200px) {
        grid-template-columns: 1fr 1fr ;
    }
    @media (min-width:1400px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    &>div{
        background: #fff;
    }
}
.ourTeamPage{
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
    @media (min-width:600px) {
            grid-template-columns: 1fr;
    }
    @media (min-width:991px) {
            grid-template-columns: 3fr 1.5fr ;
    }
    @media (min-width:1200px) {
        grid-template-columns: 3fr 1fr;
    }
}