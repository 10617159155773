.class-section {
    .title {
        text-transform: uppercase;
        color: $secondary;
    }
}

.classes-box-outer {
    display: flex;
    gap: 15px;
    flex-direction: column;


}

.classes-box-outer div:nth-child(1) {
    grid-area: ielts;


}

.classes-box-outer div:nth-child(2) {
    grid-area: pte;
}

.classes-box-outer div:nth-child(3) {
    grid-area: tofel;

}

.classes-box-outer div:nth-child(4) {
    grid-area: gre;
}

.classes-box-outer div:nth-child(5) {
    grid-area: gmat;
}

@media (min-width:62rem) {

    .classes-box-outer {
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            'ielts pte';
    }
}

@media (min-width:75rem) {
    .classes-box-outer {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            'ielts pte';
    }
}

@media (min-width:100rem) {
    .classes-box-outer {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
            'ielts ielts pte  pte';
        // 'gre gmat gmat tofel';
    }
}