.contact-page-section1 {
    img {
        display: none;
        width: 100%;
    }



    @media (min-width:62rem) {
        .d-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 50px;

            img {
                display: block;
            }


            .d-flex {
                display: flex;
                align-items: center;
                justify-content: center;


            }
        }
    }
}

.map-section{
    iframe{border:none;}
}