$base : 1rem;
$space-base:10px;



.full-height {
    height: 100%;
}

/****Align Text ****/
$align: start,
center,
end;

@each $align in $align {
    .text-#{$align} {
        text-align: $align !important;
    }
}


/**Text Color And Background Color **/
$colors: (primary : $primary,
    secondary : $secondary,
    light : $light-color);

@each $name,
$color in $colors {
    .text-#{$name} {
        color: $color !important;
    }

    .bg-#{$name} {
        background-color: $color !important;
    }
}


/****font-size**/

$font-sizes:(h1 : $base * 2,
    h2 : $base * 1.5,
    h3 : $base * 1,
    h4 : $base * 0.75,
    h5 : $base * 0.75,
    p : $base * 0.75,
    strong : $base * 0.75);

@each $tag,
$size in $font-sizes {

    #{$tag},
    .#{$tag} {
        font-size: $size;
        line-height: $size * 1.5;
    }
}

@media (min-width:35rem) {
    $font-sizes: (h1 : $base * 2.5,
            h2 : $base * 2,
            h3 : $base * 1.5,
            h4 : $base,
            h5 : $base * 0.5,
            p : $base,
            strong : $base);

    @each $tag,
    $size in $font-sizes {

        #{$tag},
        .#{$tag} {
            font-size: $size;
            line-height: $size * 1.5;
        }
    }

}

@media (min-width:75rem) {
    $font-sizes: (h1 : $base * 3.5,
            h2 : $base * 2.5,
            h3 : $base * 1.5,
            h4 : $base,
            h5 : $base * 0.5,
            p : $base,
            strong : $base);

    @each $tag,
    $size in $font-sizes {

        #{$tag},
        .#{$tag} {
            font-size: $size;
            line-height: $size * 1.5;
        }
    }

}


/***Margin Padding*/

$space: (0 : $space-base * 0,
    1 : $space-base * 1,
    2 : $space-base * 2,
    3 : $space-base * 3,
    4 : $space-base * 4,
    5 : $space-base * 5,
);

@each $tag,
$size in $space {
    .mt-#{$tag} {
        margin-top: $size;
    }

    .mb-#{$tag} {
        margin-bottom: $size;
    }

    .ml-#{$tag} {
        margin-left: $size;
    }

    .mr-#{$tag} {
        margin-right: $size;
    }

    .mx-#{$tag} {
        margin-right: $size;
        margin-left: $size;
    }

    .my-#{$tag} {
        margin-top: $size;
        margin-bottom: $size;
    }


    .pt-#{$tag} {
        padding-top: $size;
    }

    .pb-#{$tag} {
        padding-bottom: $size;
    }

    .pl-#{$tag} {
        padding-left: $size;
    }

    .pr-#{$tag} {
        padding-right: $size;
    }

    .px-#{$tag} {
        padding-right: $size;
        padding-left: $size;
    }

    .py-#{$tag} {
        padding-top: $size;
        padding-bottom: $size;
    }

}

// @media (min-width:35rem) {
//     $space: (0 : $space-base * 0,
//             1 : $space-base * 1,
//             2 : $space-base * 2,
//             3 : $space-base * 3,
//             4 : $space-base * 4,
//             5 : $space-base * 5,
//         );

//     @each $tag,
//     $size in $space {
//         .mt-#{$tag} {
//             margin-top: $size;
//         }

//         .mb-#{$tag} {
//             margin-bottom: $size;
//         }

//         .ml-#{$tag} {
//             margin-left: $size;
//         }

//         .mr-#{$tag} {
//             margin-right: $size;
//         }

//         .mx-#{$tag} {
//             margin-right: $size;
//             margin-left: $size;
//         }

//         .my-#{$tag} {
//             margin-top: $size;
//             margin-bottom: $size;
//         }


//         .pt-#{$tag} {
//             padding-top: $size;
//         }

//         .pb-#{$tag} {
//             padding-bottom: $size;
//         }

//         .pl-#{$tag} {
//             padding-left: $size;
//         }

//         .pr-#{$tag} {
//             padding-right: $size;
//         }

//         .px-#{$tag} {
//             padding-right: $size;
//             padding-left: $size;
//         }

//         .py-#{$tag} {
//             padding-top: $size;
//             padding-bottom: $size;
//         }

//     }

// }




/********Display ****/

$display: block,
inline,
inline-block,
flex,
grid;

@each $display in $display {
    .d-#{$display} {
        display: $display;
    }
}


/***** Grid template ****/

$grids:(2: 1fr 1fr,
    3: 1fr 1fr 1fr,
    4: 1fr 1fr 1fr 1fr,
    5: 1fr 1fr 1fr 1fr 1fr,
);

@each $tag,
$size in $grids {
    .grid-#{$tag} {
        display: grid;
        grid-template-columns: $size;
        gap: $base;
    }
}

/*****Icon Box *****/

.icon-box {
    height: 11.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        text-transform: uppercase;
        color: $light-color;

        font-size: 1.4rem;
        font-weight: bold;
    }
}


/****class-box*/
.class-box {
    .line {
        height: 5px;
        width: 7rem;
    }
}

/***Country Box***/
.country-box {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        img.main-img {
            transform: scale(1.1);
        }

        .no-bg-btn img {
            transform: translate(10px, 0);
        }
    }

    img.main-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 0.3s linear !important;
    }

    .title {
        position: absolute;
        bottom: 50px;
        left: 40px;
        right: 40px;
    }
}

p {
    line-height: $base *2;
}

.comming_soon_section{
    height: 80vh;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $light-color;
}