.top-ban {
    background-color: #F0F0F0;

    .d-flex {

        justify-content: center;

        &>div:first-child {
            display: none;

        }

    }


}

a {
    cursor: pointer;
}

.logo-menu {
    .logo-menu-container {
        display: flex;
        justify-content: space-between;

        .menu {
            height: 100%;
        }

        .burger-icon {
            display: flex;
            align-items: center;
            height: 100%;

            .bx-lg {
                font-size: 45px;
                height: 45px;
                width: 45px;
                color: $secondary;
            }
        }

        .logo-time {
            display: flex;
            justify-content: space-between;
            font-size: 40px;
            color: $light-color;
            cursor: pointer;
        }

        .logo {
            img {
                width: 200px;
            }
        }

        .show {
            display: block;

            .nav-bar-list {
                overflow-y: scroll;
                height: 80vh;
            }
        }

        .hide {
            display: none;
        }

        .nav-bar-box {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            backdrop-filter: blur(5px);
            background: rgba($color: #000, $alpha: 0.9);
            padding: 20px;
            z-index: 999;

            .nav-bar-list {
                list-style: none;
                padding-left: 0;

                ul {
                    list-style: none;
                    padding-left: 1rem;

                    a {
                        font-size: 1.5rem;
                    }
                }

                .nav-bar {
                    padding-bottom: 1rem;
                    position: static;

                    &>ul {

                        h2,
                        p,
                        img,
                        br {
                            display: none;
                        }

                    }


                    @media (min-width:62rem) {
                        &>ul {
                            position: absolute;
                            top: 15vh;
                            left: 60vh;
                            border-left: 1px solid $light-color;
                            padding-left: 50px;
                            padding-right: 50px;
                            height: 80vh;
                            color: $light-color;
                            display: flex;
                            align-items: center;

                            img{display: inline-block;}
                            h2,
                            p,
                            br {
                                display: block;
                            }

                            h2 {
                                margin-top: 0;
                                text-transform: uppercase;
                            }

                            .inner-dropdown-list {
                                display: grid;
                                grid-template-columns: 1fr 1fr 1fr;
                                text-align: center;

                                li {
                                    margin-top: 15px;
                                    margin-bottom: 15px;

                                    a {
                                        &:hover {
                                            color: $primary;
                                        }
                                    }
                                }

                            }
                        }

                    }
                }

                a {
                    text-decoration: none;
                    color: $light-color;
                    text-transform: uppercase;
                }

                .nav-link {
                    font-size: 2rem;
                    position: relative;
                    padding-left: 0;
                    transition: all 0.1s ease-in-out;

                    &::after {
                        content: '';
                        height: 100%;
                        width: 0px;
                        background: $light-color;
                        position: absolute;
                        left: 0;
                        transition: all 0.1s ease-in-out;
                    }

                    &:hover {
                        padding-left: 30px;

                        &::after {
                            width: 5px;
                        }

                    }
                }
            }
        }


    }
}

@media (min-width:75rem) {
    .web-menu {
        display: block !important;
        height: 100%;
    }

    .burger-icon,
    .burger-outer {
        display: none !important;
    }

    .navbar-web-outer {
        .navbar-web-list {
            gap: 35px !important;
        }
    }
}

@media (max-width:62rem) {
    .web-menu {
        display: none;
    }
}

@media (min-width:62rem) {
    .web-menu {
        display: none;
    }

    .burger-icon,
    .burger-outer {
        display: block;
    }

    .navbar-web-outer {
        height: 100%;

        .navbar-web-list {
            list-style: none;
            padding-left: 0;
            display: flex;
            gap: 20px;
            align-items: center;
            height: 100%;

            .navbar-web-link {
                text-decoration: none;
                font-weight: 500;
                color: $secondary;
                padding-top: 5px;
                padding-bottom: 5px;
                border-bottom: 0px solid $primary;
                transition: all 0.1s linear;

                &:hover {
                    color: $primary;
                    border-bottom-width: 3px;
                }
            }
        }
    }
}

@media (min-width:36rem) {
    .top-ban {
        .d-flex {
            justify-content: space-between;
            flex-direction: row;

            &>div:first-child {
                display: block;
            }
        }
    }

    .logo-menu {
        .logo-menu-container {
            display: flex;
            justify-content: space-between;

            .logo {
                img {
                    width: 100%;
                }
            }


        }
    }
}