.slider {

    position: relative;
    height: auto;
    overflow: hidden;

    @media (min-width:75rem) {
        height: 70vh;
        overflow: hidden;
    }
    @media (min-width:100rem) {
        height: 80vh;
        overflow: hidden;
    }

    .slider-arrow {
        position: absolute;
        top: auto;
        bottom: 0;
        width: 50px;
        height: 50px;
        background: $primary;
        cursor: pointer;
        padding: 1rem;
        left: 0;
        z-index: 99;
        color: $light-color;


        &.next {
            left: 82px;
            transform: rotate(180deg);
        }
    }

    .slide {
        opacity: 1;
        transition: all 0.3s linear;

        .img-person {
            transform: translate(0px, 80px);
            transition-duration: 0.3s;
        }
    }

    .slide.active {
        opacity: 1;
        transition: all 0.3s linear;
        height: 100%;

        .slider-img {
            animation-duration: 3s;
            animation-name: slider-img;
            animation-fill-mode: forwards;

            @keyframes slider-img {
                from {
                    transform: scale(1.3);
                }

                to {
                    transform: scale(1.0);
                }
            }
        }

        .img-person {
            animation-duration: 1s;
            animation-name: slider-person;
            animation-fill-mode: forwards;
            animation-delay: 2s;
            opacity: 0;

            @keyframes slider-person {
                from {
                    transform: translate(0px, 80px);
                    transform: scale(1.1);
                    opacity: 1;
                }


                to {
                    transform: translate(0px, 0px);
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }

        .slider-caption {
            animation-duration: 1s;
            animation-name: slider-caption;
            animation-fill-mode: forwards;
            animation-delay: 1s;
            opacity: 0;
            bottom: 100px;
            background-color: none;
            padding: 20px !important;

            P,
            a {
                display: none;
            }


            @media (min-width:62rem) {
                bottom: 0;
                padding: 40px !important;

                P,
                a {
                    display: block;
                }

            }

            @keyframes slider-caption {
                from {
                    transform: translate(100px, 00px);
                    opacity: 0;
                }

                to {
                    transform: translate(0px, 0px);
                    opacity: 1;
                }
            }
        }


    }

    .img-person {
        height: 100%;
        position: absolute;
        bottom: 0;
        z-index: 9;
        left: 0;
        width: auto;
        display: none;

    }

    img.slider-img {
        width: 100%;
        height: 100%;
        object-fit: cover;

    }

    .slider-caption {

        position: absolute;
        right: 0;
        bottom: 0;
        background: rgba($color: $primary, $alpha: 0.9);
        left: auto;
        max-width: 500px;
        backdrop-filter: blur(5px);
        z-index: 99;

        h2,
        h1 {
            text-transform: uppercase;
        }

        h2 {
            line-height: 30px;
        }

        h1 {
            font-weight: 900;
        }
    }
}

@media (min-width:75rem) {
    .slider .img-person {
        display: block;
    }
}