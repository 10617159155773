$btn-colors: 
    (
        primary : $primary,
        secondary : $secondary    
    );
@each $name,$color in $btn-colors{
    .btn-#{$name}{
        width:140px;
        height:40px;
        font-size:$base;
        line-height:30px;
        border:none;
        border-radius:0;
        background-color:$color;
        color:$light-color;
        transition:all 0.3s linear;
        cursor:pointer;
        position:relative;
        &:hover{
            opacity:0.8;
        }
        &:focus{
            outline:none;
        }
        &.icon-right{
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }
        
    }
}
.no-bg-btn{
    display:flex;
    justify-content:space-between;
    width:162px;
    color:$light-color;
    cursor:pointer;
    img{transform:translate(0,0); transition:all 0.3s linear}

    &:hover{
        img{transform:translate(10px,0)}
    }
    
}
.no-bg-btn-full{
    display:flex;
    justify-content:space-between;
    cursor:pointer;
    color:$light-color;
    img{transform:translate(0,0); transition:all 0.3s linear}

    &:hover{
        img{transform:translate(10px,0)}
    }
    
}
.Watsapp{
position: fixed;
    z-index: 99999;
    bottom: 80px;
    right: 60px;
    border: none;
    background: #25d366;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    transform: scale(1.3);

    
}
