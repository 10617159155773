@import 'scss/abstracts/color';
@import 'scss/base/type-face';
@import 'scss/base/base';
@import 'scss/base/reset';
@import 'scss/utilities/container';
@import 'scss/components/button';
@import 'scss/components/header';
@import 'scss/components/slider';
@import 'scss/components/welcome';
@import 'scss/components/country';
@import 'scss/components/class';
@import 'scss/components/universityContact';
@import 'scss/components/contactform';
@import 'scss/components/footer';
@import 'scss/layout/country';
@import 'scss/layout/classes';
@import 'scss/layout/contact';

.lazy-load-image-background.blur.lazy-load-image-loaded{width: 100%;}