.content-box-2 {
    // height: 90vh;

    img.main {
        width: 100%;
        display: none;
    }

    h1 {
        text-transform: uppercase;
        color: $primary;
        font-weight: 800;


        span {
            display: block;
            font-weight: 500;
            text-transform: capitalize;
            color: $secondary;
        }
    }

    .content-line-box {
        // margin-inline: 20vh;
        padding-left: 20px;
        border-left: 10px solid $primary;

    }

    .content-icon-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .icon-boxs {
            background-color: $light-color !important;
            margin-top: 30px;
            margin-bottom: 30px;

            .icon-box {
                background: $primary;
            }
        }
    }


    .d-flex {
        flex-direction: column;
        gap: 0;
    }





}

@media (min-width:35rem) {

    .content-box-2 {
        .content-icon-box {
            &>div>div {
                padding-left: 50px;
                padding-right: 50px;
            }

            .icon-boxs {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }
    }


}

@media (min-width:49rem) {
    .content-box-2 {
        .content-icon-box {
            .icon-boxs {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }

        
    }

}

@media (min-width:62rem) {
    .content-box-2 {
        .content-icon-box {
            .icon-boxs {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }

        img.main {
            display: none;
        }


    }
}

@media (min-width:75rem) {
    .content-box-2 {
        position: relative;
        padding-bottom: 250px;

        .d-flex {
            display: grid;
            grid-template-columns: 1fr 1fr !important;
        }

        img.main {
            display: block;
            height: 100%;
            object-fit: cover;
        }


    }
}

@media (min-width:87.5rem) {
    .icon-boxs {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
    .content-box-2 {
        position: relative;
        padding-bottom: 50px;

        .content-line-box {
            margin-left: 70px;
        }

        .d-flex {
            display: grid;
            grid-template-columns: 1fr 1fr !important;
        }

        img.main {
            display: block;
            height: 100%;
            object-fit: cover;
        }

        .icon-boxs {
            position: relative;
            left: 0;
            right: 0;
            z-index: 9;
            bottom: 0;
            transform: translate(120px, 10px);
        }

    }
}

@media (min-width:100rem) {
    .content-box-2 {
        // height: 90vh;

        img.main {
            height: 90vh;
        }
    }
}