*:focus {
    outline: 1px solid $primary;
}
a{text-decoration: none;}
a:focus {
    outline: none;
}

.contact-form-section {
    display: flex;
    align-items: center;
    height: 89%;

    &>div {
        width: 100%;
    }

    form {
        div {
            display: grid;
            grid-gap: 30px;
            grid-template-columns: 1fr;

            @media (min-width:49rem) {
                grid-template-columns: 1fr 1fr;
            }
        }

        .input-text {
            border: #CDCDCD 1px solid;
            background-color: rgba($color: #fff, $alpha: 0.5);
            padding-inline: 15px;
            min-height: 50px;

        }

        textarea {
            margin-top: 30px;
            border: #CDCDCD 1px solid;
            width: calc(100% - 30px);
            padding: 50px;
            font-size: 1rem;
            font-family: 'Poppins', sans-serif;
            padding-top: 15px;
            padding-bottom: 15px;
        }


    }
}