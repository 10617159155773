.innerslider {

    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .caption {
        padding: 20px;

    }

    @media (min-width:35rem) {
        height: 82vh;
        overflow: hidden;

        .caption {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 40px;
            max-width: 500px;
        }

    }
}

.education-system-section {
    .container{
        position:relative;
    }
    .d-grid {
        grid-template-columns: 1fr;
        

        
        .main-img{
            display:none;
        }
        h1{text-transform:uppercase; line-height:60px}
        h2{
            text-transform:uppercase;
        }      
    }
    .icon-boxes{
        display:grid;
        grid-template-columns:repeat(1, 1fr);
        grid-gap:10px;
        
        .icon-box{
            p{
                margin-bottom:0;
            }
        }
    }
    @media (min-width:49rem){
        .d-grid {
            grid-template-columns: 1fr;
            grid-gap:50px;  
             
        }
        .icon-boxes{
            display:grid;
            grid-template-columns:repeat(4, 1fr);
            
            bottom:0;
            .icon-box{
                p{
                    margin-bottom:0;
                }
            }
        }
    }
    @media (min-width:68rem){
        .d-grid{
            grid-template-columns: 1.5fr 1fr;
            .main-img{
                display:block;
                height:80vh;
                object-fit:cover;
                width:100%;
            }
        }
        .icon-boxes{
            grid-template-columns:repeat(4,1fr);
            position:relative;
        }
        
    }
    @media (min-width:92rem){
        .icon-boxes{position:absolute; width:70%}
    }
}
.visaRequiremnt{
    h2{text-transform:uppercase}
    .d-grid{
        
        grid-gap:20px;
        & div:nth-child(2){
            display:flex;
            align-items:center;
            height:100%;
            
        }
        img{width:100%; display:none}
        
    }
    @media (min-width:75rem){            
            .d-grid{
                grid-template-columns:1fr 1fr;
                grid-gap:50px;
                img{width:100%; display:block;}
            }
            
        }
}