.container {
    padding: 0 1.5rem;
    margin: 0 auto;
    max-width: 120rem;
}

@media (min-width:49rem) {
    .container {
        max-width: 45rem;
    }
}


@media (min-width:62rem) {
    .container {
        max-width: 1200rem;
    }
}

@media (min-width:75rem) {
    .container {
        max-width: 1200rem;
        padding:0 2.5rem;
    }
}