body,html {
    font-family: 'Poppins', sans-serif;
    font-size: $base;
    margin: 0 auto;
    padding: 0 auto;
    scroll-behavior: smooth;
}

.no-scroll {
    overflow: hidden;
}